<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Below is a structure of vanillin. Why can two molecules of vanillin not react together via
        the aldol condensation reaction?
      </p>

      <p class="mb-4 pl-6">
        <v-img style="max-width: 400px" src="/img/assignments/UCIrvine/vanillin.png" />
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI51PLFS1Q1',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {text: 'It does not contain an α-hydrogen.', value: 'a'},
        {text: 'It does not contain an α-carbon.', value: 'b'},
        {text: 'It does not contain an β-hydrogen.', value: 'c'},
        {text: 'It does not contain an β-carbon.', value: 'd'},
      ],
    };
  },
};
</script>
<style scoped></style>
